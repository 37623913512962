<!-- Used for admin to add club or dept -->
<!-- TODO: Beautify the layout -->
<template>
	<div id="addasso-wrapper">
		<h2>添加比赛</h2>
		<ul class="addasso-additem">
			<li>
				<p class="textleft">比赛简称</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputName"
					placeholder="比赛简称"
				></el-input>
			</li>
			<li>
				<p class="textleft">比赛全称</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputFullName"
					placeholder="比赛全称"
				></el-input>
			</li>
			<li>
				<p class="textleft">负责协会</p>
				<div class="addasso-additem-input">
					<el-select v-model="gameclub" filterable placeholder="请选择">
						<el-option
							v-for="item in clubList"
							:key="item.clubid"
							:label="item.clubname"
							:value="item.clubid"
						>
						</el-option>
					</el-select>
				</div>
			</li>
			<li>
				<p class="textleft">比赛类型</p>
				<el-select v-model="gametype" filterable placeholder="请选择">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</li>
			<li>
				<p class="textleft">比赛属性</p>
				<div class="addasso-additem-input">
					<el-radio v-model="inputSelectAttr" label="1">必选</el-radio>
					<el-radio v-model="inputSelectAttr" label="2">限选</el-radio>
					<el-radio v-model="inputSelectAttr" label="3">任选</el-radio>
				</div>
			</li>
			<li>
				<p class="textleft">分数倍数</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputMultiple"
					placeholder="分数倍数"
				></el-input>
			</li>
			<li>
				<p class="textleft">积分规则</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputScore"
					placeholder="请依次输入各名次得分，如9,8,7,6,5代表第一名9分，第二名8分，以此类推"
				></el-input>
			</li>
			<li>
				<el-button
					class="addbtn"
					type="danger"
					style="float: left"
					@click="clearall()"
					>重填</el-button
				>
				<el-button
					class="addbtn"
					type="primary"
					style="float: right"
					@click="commitCheck"
					>提交</el-button
				>
			</li>
		</ul>
		<p></p>
		<el-dialog title="确认提交" :visible.sync="commitVisible" width="30%">
			<span style="color: red">您将提交以下比赛信息</span>
			<p />
			<p>比赛名称： {{ this.inputName }}</p>
			<p>比赛全称： {{ this.inputFullName }}</p>
			<p>负责协会ID： {{ this.gameclub }}</p>
			<p>比赛类型： {{ this.gametype }}</p>
			<p>比赛属性： {{ this.inputSelectAttr }}</p>
			<p>分数倍数： {{ this.inputMultiple }}</p>
			<p>积分规则： {{ this.inputScore }}</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="commitVisible = false" style="margin-right: 4px"
					>取消</el-button
				>
				<el-button type="primary" @click="commitNow()">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<style scoped>
.addasso-additem {
	list-style-type: none;
	display: block;
	width: 40rem;
	margin: auto;
}
.addasso-additem >>> li {
	margin-bottom: 5px;
}
.addasso-additem .textleft {
	text-align: left;
	position: relative;
	padding-left: 8px;
	border-left: 4px solid #1fcab3;
}
.addasso-additem-input {
	width: 100%;
}
li .addbtn {
	margin: 0.7rem 5%;
	width: 35%;
}
</style>
<script>
import axios from "axios";
import qs from "qs";

export default {
	name: "AddClub",
	data() {
		return {
			options: [
				{
					value: "马杯",
					label: "马杯比赛",
				},
				{
					value: "首高团体",
					label: "首高团体比赛",
				},
				{
					value: "首高个人",
					label: "首高个人比赛",
				},
				{
					value: "材料",
					label: "提交材料审核",
				},
			],
			gametype: "",
			gameclub: "", // .clubid for id and .clubname for name
			inputName: "",
			inputFullName: "",
			inputSelectAttr: "",
			inputScore: "",
			inputMultiple: "",
			clubList: [
				{
					value: "材料",
					label: "提交材料审核",
				},
			],
			commitVisible: false,
			clearVisible: false,
		};
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
		}
		axios({
			url: "/api/admin/asso/get?type=club",
			method: "GET",
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
			.then((res) => {
				// console.log("club list is");
				this.clubList = res.data.data;
				// console.log(this.clubList);
			})
			.catch((err) => {
				this.messageBox(
					"获取协会列表失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log("Get clubList Failed");
				console.log(err.response);
			});
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		clearall() {
			this.$confirm("确认清空？").then((_) => {
				console.log(_);
				this.inputName = "";
				this.inputFullName = "";
				this.gameclub = "";
				this.gametype = "";
				this.inputSelectAttr = "";
				this.inputMultiple = "";
				this.inputScore = "";
			});
		},
		commitCheck() {
			if (!this.inputName) {
				this.messageBox("请输入比赛名称", "error");
				return false;
			}
			if (!this.inputFullName) {
				this.messageBox("请输入比赛全称", "error");
				return false;
			}
			if (!this.gameclub) {
				this.messageBox("请选择负责协会", "error");
				return false;
			}
			if (!this.gametype)
				if (!this.inputFullName) {
					this.messageBox("请选择比赛类型", "error");
					return false;
				}
			if (!this.inputSelectAttr) {
				this.messageBox("请选择比赛属性", "error");
				return false;
			}
			if (this.inputMultiple > 20 || this.inputName < 1) {
				this.messageBox("分数倍数是一个1-20的整数", "error");
				return false;
			}
			if (!this.inputScore) {
				this.messageBox("请输入积分规则", "error");
				return false;
			}
			let scoreArr = this.inputScore.split(",");
			for (let item of scoreArr)
				if (item != parseInt(item)) {
					this.messageBox(
						"积分规则输入有误，请输入以英文逗号分隔的整数",
						"error"
					);
					return false;
				}
			this.commitVisible = true;
		},
		commitNow() {
			if (localStorage.getItem("token")) {
				axios.defaults.headers.common["Authorization"] = localStorage.getItem(
					"token"
				);
			}
			this.commitVisible = false;
			axios({
				url: "/api/admin/game/add",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
				data: qs.stringify({
					gamename: this.inputName,
					gamefullname: this.inputFullName,
					gametype: this.gametype,
					gameclub: this.gameclub,
					gameselectattr: this.inputSelectAttr,
					gamemultiple: this.inputMultiple,
					gamescore: "[" + this.inputScore + "]",
				}),
			})
				.then((res) => {
					console.log(res);
					this.messageBox("添加" + this.inputName + "成功", "success");
					this.inputName = "";
					this.inputFullName = "";
					this.gametype = "";
					this.gameclub = "";
					this.inputSelectAttr = "";
					this.inputMultiple = "";
					this.inputScore = "";
				})
				.catch((err) => {
					this.messageBox(
						"添加失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Add game Failed");
					console.log(err.response);
				});
		},
	},
};
</script>